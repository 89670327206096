import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { useGeolocated } from 'react-geolocated';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import './App.css'; // Add a CSS file for additional styling

function App() {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [startAddress, setStartAddress] = useState('');
  const [endAddress, setEndAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isNavigating, setIsNavigating] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [startLocationInitialized, setStartLocationInitialized] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const routingControlRef = useRef(null);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    watchPosition: true,
    userDecisionTimeout: 10000, // Increased timeout to 10 seconds
  });

  const geocode = async (address) => {
    const apiKey = 'r1adWR2fX_y8xp6a3tgqQ3KdQ55DsEk_T5Wjb3rqd2c';
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(address)}&apiKey=${apiKey}`;
    try {
      const response = await axios.get(url);
      if (response.data.items.length === 0) {
        throw new Error('Nie znaleziono wyników dla podanego adresu');
      }
      const location = response.data.items[0].position;
      return { lat: location.lat, lng: location.lng };
    } catch (error) {
      console.error("Błąd podczas geokodowania adresu: ", error);
      return null;
    }
  };

  const reverseGeocode = async (lat, lng) => {
    const apiKey = 'r1adWR2fX_y8xp6a3tgqQ3KdQ55DsEk_T5Wjb3rqd2c';
    const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${lng}&apiKey=${apiKey}`;
    try {
      const response = await axios.get(url);
      if (response.data.items.length === 0) {
        throw new Error('Nie znaleziono wyników dla podanych współrzędnych');
      }
      const address = response.data.items[0].address;
      return `${address.label}`;
    } catch (error) {
      console.error("Błąd podczas odwrotnego geokodowania współrzędnych: ", error);
      return `Lat: ${lat}, Lng: ${lng}`;
    }
  };

  const getRoute = async () => {
    setErrorMessage('');
    const startLocation = await geocode(startAddress);
    const endLocation = await geocode(endAddress);

    if (!startLocation || !endLocation) {
      setErrorMessage('Nie można znaleźć lokalizacji dla podanych adresów. Proszę spróbować ponownie.');
      return;
    }

    setStart(startLocation);
    setEnd(endLocation);

    // Fetch routes from the OSRM API
    const url = `https://router.project-osrm.org/route/v1/driving/${startLocation.lng},${startLocation.lat};${endLocation.lng},${endLocation.lat}?alternatives=true&geometries=geojson&overview=full`;
    try {
      const response = await axios.get(url);
      setRoutes(response.data.routes);
      setSelectedRoute(response.data.routes[0]);
      setIsNavigating(false); // Reset navigation state

      // Reset routing control if it exists
      if (routingControlRef.current) {
        routingControlRef.current.getPlan().setWaypoints([]);
        routingControlRef.current._map.removeControl(routingControlRef.current);
        routingControlRef.current = null;
      }
    } catch (error) {
      console.error("Błąd podczas pobierania tras: ", error);
      setErrorMessage('Nie można pobrać tras. Proszę spróbować ponownie.');
    }
  };

  const RoutingMachine = () => {
    const map = useMap();

    useEffect(() => {
      if (selectedRoute && isNavigating) {
        if (routingControlRef.current) {
          routingControlRef.current.getPlan().setWaypoints([]); // Clear existing waypoints
          map.removeControl(routingControlRef.current);
        }

        const waypoints = [
          L.latLng(start.lat, start.lng),
          L.latLng(end.lat, end.lng),
        ];

        routingControlRef.current = L.Routing.control({
          waypoints,
          router: L.Routing.osrmv1({
            serviceUrl: `https://router.project-osrm.org/route/v1`
          }),
          lineOptions: {
            styles: [{ color: 'blue', weight: 4 }]
          },
          show: true,
          addWaypoints: false,
          fitSelectedRoutes: true,
          showAlternatives: false,
          language: 'en', // Set the language to English
          createMarker: function () { return null; }, // Disable default markers
        }).addTo(map);

        routingControlRef.current.on('routesfound', function (e) {
          const routes = e.routes;
          const summary = routes[0].summary;
          console.log(`Total distance is ${summary.totalDistance / 1000} km`);
          console.log(`Total time is ${summary.totalTime / 60} minutes`);
        });

        // Center the map on the user's current location initially
        if (!startLocationInitialized && coords) {
          map.setView([coords.latitude, coords.longitude], 13);
          setStartLocationInitialized(true);
        }
      }
    }, [selectedRoute, isNavigating, map]);

    return null;
  };

  const CenterMapOnCurrentLocation = () => {
    const map = useMap();

    useEffect(() => {
      if (coords && !startLocationInitialized) {
        map.setView([coords.latitude, coords.longitude], 13);
        setStartLocationInitialized(true);
      }
    }, [coords, map, startLocationInitialized]);

    useEffect(() => {
      if (currentLocation && !isNavigating) {
        map.setView([currentLocation.lat, currentLocation.lng], 25); // Zoom in to level 25 for detailed view
      }
    }, [currentLocation, map, isNavigating]);

    return null;
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
  };

  const handleStartNavigation = () => {
    setIsNavigating(true);
    setRoutes([selectedRoute]);  // Remove other routes
  };

  const handleRouteSelection = (route) => {
    setSelectedRoute(route);
    setIsNavigating(false); // Reset navigation state

    // Reset routing control if it exists
    if (routingControlRef.current) {
      routingControlRef.current.getPlan().setWaypoints([]);
      routingControlRef.current._map.removeControl(routingControlRef.current);
      routingControlRef.current = null;
    }
  };

  useEffect(() => {
    if (isNavigating && coords) {
      const updateLocation = debounce((position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      }, 1000); // 1-second debounce

      const watchId = navigator.geolocation.watchPosition(
        (position) => updateLocation(position),
        (error) => {
          console.error('Błąd podczas śledzenia pozycji:', error);
        },
        { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 } // Increased timeout to 10 seconds
      );

      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, [isNavigating, coords]);

  useEffect(() => {
    const initializeCurrentLocation = async () => {
      if (coords) {
        const address = await reverseGeocode(coords.latitude, coords.longitude);
        setStart({ lat: coords.latitude, lng: coords.longitude });
        setStartAddress(address);
      }
    };

    if (coords && !startLocationInitialized) {
      initializeCurrentLocation();
      setStartLocationInitialized(true);
    }
  }, [coords, startLocationInitialized]);

  return (
    <div>
      <div className="controls">
        <label>
          Adres początkowy:
          <input type="text" value={startAddress} onChange={(e) => setStartAddress(e.target.value)} placeholder="np. Warszawa, Polska" />
        </label>
        <label>
          Adres końcowy:
          <input type="text" value={endAddress} onChange={(e) => setEndAddress(e.target.value)} placeholder="np. Kraków, Polska" />
        </label>
        <button onClick={getRoute}>Wyznacz trasę</button>
        <button onClick={handleStartNavigation}>Rozpocznij nawigację</button>
      </div>
      <div className="controls">
        <button onClick={async () => {
          if (coords) {
            const address = await reverseGeocode(coords.latitude, coords.longitude);
            setStart({ lat: coords.latitude, lng: coords.longitude });
            setStartAddress(address);
          }
        }}>
          Użyj bieżącej lokalizacji
        </button>
      </div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {routes.length > 0 && (
        <div className="routes">
          <h3>Wybierz trasę:</h3>
          {routes.map((route, index) => (
            <div key={index} onClick={() => handleRouteSelection(route)} style={{ cursor: 'pointer', padding: '5px', border: selectedRoute === route ? '2px solid blue' : '1px solid gray' }}>
              Trasa {index + 1}: {Math.round(route.distance / 1000)} km, {Math.round(route.duration / 60)} minut
            </div>
          ))}
        </div>
      )}
      <MapContainer id="map" className="map-container" center={[coords ? coords.latitude : 51.505, coords ? coords.longitude : -0.09]} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributorzy'
        />
        {coords && (
          <Marker position={[coords.latitude, coords.longitude]}>
            <Popup>Bieżąca lokalizacja</Popup>
          </Marker>
        )}
        {routes.map((route, index) => (
          <Polyline
            key={index}
            positions={route.geometry.coordinates.map(coord => [coord[1], coord[0]])}
            color={route === selectedRoute ? 'blue' : 'gray'}
            weight={4}
          />
        ))}
        {isNavigating && selectedRoute && <RoutingMachine />}
        <CenterMapOnCurrentLocation />
      </MapContainer>
    </div>
  );
}

export default App;
